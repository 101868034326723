import React from "react";
import { RollableTable } from "../RollableTable";

const SettlementScales = [
    "A hovel, home to an isolated frontier individual or family working a remote job. Could contain some goods if the residence serves as a trading post. Only people familiar with the area likely know about this location. This settlement probably has at most one interesting feature, if any.",
    "A small village home to a few households. Contains relatively few resources, mostly just what the locals intend to sell to larger markets. Some people may have heard of this area, but only if it was part of a more interesting story. This settlement probably has about one interesting feature.",
    "A quiet town, perhaps an outlying settlement to a larger city-state. Less common objects might be obtainable here, but specialty items are not generally available. Very common goods are generally accessible. This type of settlement is probably known in the region, though it's also know that it isn't especially important. This settlement has one or two interesting features.",
    "A moderately sized town. Common items are fairly easy to come by, and even some rarer items might be in stock, but don't count on it. A village of this size might be part of a larger state. Towns of this size are usually fairly reputable, most people in the area have heard of it and can probably list at least one of its features. This settlement likely has about two interesting features.",
    "A large city. Common items are readily accessible, rarer goods are obtainable, and there is always plenty to do and see. The city is large enough that it likely has multiple criminal gangs competing for resources, complex politics, and a standing army. Anyone educated in statecraft would be familiar with this settlement. This settlement has about three interesting features.",
    "A metropolis. This is an influential city or keystone city of a larger empire. This city is likely a major player in geopolitics and is the ire of many a would-be conquerer. Only the rarest of goods can't be found in a settlement of this magnitude, but you can probably find a treasure map that can get them. Everyone knows about this city. Even people far away have likely heard tales and legends about it. A settlement of this scale has at least four interesting features."
]
export const SettlementScaleTable = () => <RollableTable columnName="Settlement Scale" outcomes={SettlementScales} />


const SettlementFeatures = [
    "The settlement contains a notable university or place of higher learning.",
    "The settlement is home to a famous individual, perhaps a legendary warrior or renouned sorcerer.",
    "The inhabitants all belong to a cult that worship a great entity from before The Cataclysm",
    "The settlement is recovering from a large natural disaster such as a Geigernado or a Chlorocane.",
    "The settlement is currently experiencing economc boom.",
    "The settlement is currently experiencing economic bust.",
    "The settlement's youth have been disappearing under mysterious circumstances.",
    "The inhabitants are currently suffering from a plague.",
    "A den of ogres have occupied a nearby cave.",
    "Raiders have moved into the area begun waylaying travelers.",
    "Strange mutated creatures have been roaming and killing inhabitants and livestock.",
    "The settlement is located next to an archeological find and some issues have come up with the excavation.",
    "An infamous outlaw is lodging in town, but nobody has felt brave enough to confront them.",
    "A child in the area has come across a powerful spellcasting artifact that they cannot control.",
    "A sorcerer cursed the land many years ago (e.g. used a spell to poison the land with harmful chemicals or radiation) and the inhabitants are desperate for someone to lift the curse (clean up the sorcerer's mess).",
    "The settlement's primary source of income is being disrupted by raiders, wild beasts, or some other external force."
];
export const SettlementFeaturesTable = () => <RollableTable columnName="Settlement Features" outcomes={SettlementFeatures} />



const SettlementLeadership = [
    "This settlement has almost no leadership, it's more of a confederation of families than a serious political entity. This isn't likely to work for anything larger than a quiet town.",
    "This settlement utilizes communal decision making. This is less likely to work for anything larger than a quiet town.",
    "This settlement is ruled by a warlord or dictator with absolute power.",
    "This settlement is ruled by a few powerful noble families. This is less likely for small townships with too few families to have an oligarchic elite.",
    "This settlement is ruled by elected representatives.",
    "This settlement makes decisions by putting everything to a vote.",
]
export const SettlementLeadershipTable = () => <RollableTable columnName="Leadership Structure" outcomes={SettlementLeadership} />



const SettlementPoliticalFeatures = [
    "The settlement is highly corrupt and several officials or powerful individuals are indebted or puppeted by criminal enterprise.",
    "One or more key residents of the settlement is/are being held for ransom.",
    "There is large scale political unrest; a large portion of the population is on the verge of rebellion.",
    "There is relative peace here, the settlement appears to have things mostly under control and the populace is mostly satisfied with life.",
    "The leadership here is greatly respected, perhaps they are local heroes or have brought prosperity to the settlement.",
    "This settlement has a rivalry with a different, nearby settlement.",
    "This settlement's leadership or influential individuals are old fashioned and unpopular, but nobody appears ready to take the reins just yet.",
    "This settlement's leadership is weak and ineffectual, but nobody else is willing to step up.",
    "This settlement has an abundance of red tape. Everything you do seems to require a permit of some sort or is simply outright forbidden.",
    "This settlement features a rigid caste system.",
]
export const SettlementPoliticalFeaturesTable = () => <RollableTable columnName="Political Features" outcomes={SettlementPoliticalFeatures} />
