import React, { useState } from "react"
import { EnemyFilter, EnemyFilterInitializer } from "../../../entities/filters/EnemyFilter";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store/stores/ApplicationState";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { encodeNameForURI } from "../../../utils/StringUtilities";
import { Loader } from "../../theming/loader/Loader";
import { EnemyFilterComponent } from "./EnemyFilterComponent";

export const EnemyResults = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { enemies, allEnemiesLoaded } = useSelector((state: ApplicationState) => state.library);
    const query = new URLSearchParams(location.search);
    let queryFilter = EnemyFilter.CreateFilterFromQuery(query);
    const queryInitializer = queryFilter.getInitializer();
    const [filter, setFilter] = useState(queryInitializer);

    queryFilter = new EnemyFilter(filter);
    // navigate(queryFilter.generateQueryString(), { replace: true });

    const filteredEnemies = queryFilter.filter(enemies);
    return (
        <section className="search-results">
            {!allEnemiesLoaded && <Loader />}
            {allEnemiesLoaded && <>
                <EnemyFilterComponent filter={queryFilter} updateFilter={setFilter} />
                <table className="themed-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Level</th>
                            <th>Tags</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEnemies.map(enemy => (
                            <tr key={enemy.id}>
                                <td>{enemy.name}</td>
                                <td>{enemy.level}</td>
                                <td>{enemy.tags.join(', ')}</td>
                                <td><Link to={`${encodeNameForURI(enemy.name)}`}>View</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>}
        </section>
    )
}