import { PlayerAccount } from "../../../entities/user/PlayerAccount";

export type PlayerState = {
    me: PlayerAccount | null;
    adventureConnectionId: string | null;
    loadingMe: boolean;
    players: PlayerAccount[];
}

export const PlayerDefaultState: PlayerState = {
    me: null,
    adventureConnectionId: null,
    loadingMe: true,
    players: [],
}