import React, { useState } from "react"
import { CreateFigureDiceRows } from "../common/FigureDice"
import { ThemedButton } from "../../../inputs/buttons/ThemedButton"
import { RollIcon } from "../../../icons/CharacterIcons"
import { Loader } from "../../../theming/loader/Loader"

type Props = {
    columnName: React.ReactNode;
    outcomes: string[];
}

export const RollableTable = (props: Props) => {
    const { columnName, outcomes } = props;
    const [currentOutcome, setCurrentOutcome] = useState<string | null>(null);
    const [rolling, setRolling] = useState(false);
    const rollOutcome = () => {
        setRolling(true);
        const index = Math.floor(Math.random() * outcomes.length);
        setCurrentOutcome(outcomes[index]);
        setTimeout(() => setRolling(false), 500);
    }
    return (
        <table className="themed-table">
            <thead>
                <tr>
                    <th>Roll</th>
                    <th>{columnName}</th>
                </tr>
            </thead>
            <tbody>
                {CreateFigureDiceRows(outcomes)}
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <ThemedButton onClick={rollOutcome}><RollIcon /> Roll!</ThemedButton>
                    </td>
                    <td>
                        {rolling && <Loader />}
                        {!rolling && <>
                            
                            {!currentOutcome && <strong>Roll the dice or pick an option!</strong>}
                            {currentOutcome && <>
                                <strong>Result</strong>
                                <hr />
                                {currentOutcome}
                            </>}
                        </>}
                    </td>
                </tr>
                
            </tfoot>
        </table>
    );
}