import { Route, Routes } from "react-router";
import React, { useEffect } from "react";
import { EnemyViewer } from "./EnemyViewer";
import { EnemyResults } from "./EnemyResults";
import { LibraryActions } from "../../../store/stores/library/LibraryStore.Actions";
import { useAppDispatch, useAppSelector } from "../../../store/configureStore";

export const EnemiesLibrary = () => {
    const dispatch = useAppDispatch();
    
    const { allEnemiesLoaded } = useAppSelector(app => app.library);
    useEffect(() => {
        if (!allEnemiesLoaded) {
            dispatch(LibraryActions.loadEnemies());
        }
    }, [dispatch, allEnemiesLoaded]);
    return (
        <Routes>
            <Route path=":name" element={<EnemyViewer />} />
            <Route path="*" element={<EnemyResults />} />
        </Routes>
    )
}
