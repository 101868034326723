import { AdventureEventComponent } from "../../../components/collection/adventures/AdventureEventComponent";
import { Adventure, CommunalDice } from "../../../entities/adventures/Adventure";
import { AdventureEvent } from "../../../entities/adventures/AdventureEvent";
import { CharacterInitializer } from "../../../entities/characters/Character";
import { SortedSet } from "../../../entities/data-structures/SortedSet";
import { ErrorToast, InfoToast, SuccessToast } from "../../../entities/toasts/Toasts";
import { AppThunkAction } from "../ApplicationState";
import { SheetSetCommunalRolls } from "../characters/sheet/actions/SheetToolActions";
import { ToastAction, ToastDispatchables } from "../toasts/Toasts.Actions";
import { UniversalActions } from "../UniversalActions";
import { UserActions } from "../players/PlayerStore.Actions";
import { ApiDefaultState } from "../api/ApiStore.State";

export type AdventuresLoaded = { type: 'ADVENTURES_LOADED', adventures: Adventure[] }
export type AdventuresLoadError = { type: 'ADVENTURES_LOAD_ERROR' }
export type AdventureCreated = { type: 'ADVENTURE_CREATED', adventure: Adventure }
export type AdventureUpdateName = { type: 'ADVENTURE_UPDATE_NAME', id: string, name: string };
export type AdventureUpdateContentSharing = { type: 'ADVENTURE_UPDATE_CONTENT_SHARING', id: string, allowContentSharing: boolean }
export type AdventureUpdateInvite = { type: 'ADVENTURE_UPDATE_INVITE', id: string, inviteId: string }
export type AdventureUpdatePublicNotes = { type: 'ADVENTURE_UPDATE_PUBLIC_NOTES', id: string, publicNotes: string }
export type AdventureUpdatePrivateNotes = { type: 'ADVENTURE_UPDATE_PRIVATE_NOTES', id: string, privateNotes: string }
export type AdventurePlayerJoined = { type: 'ADVENTURE_PLAYER_JOINED', id: string, playerId: string }
export type AdventurePlayerLeft = { type: 'ADVENTURE_PLAYER_LEFT', id: string, playerId: string }
export type AdventureCharacterAdded = { type: 'ADVENTURE_CHARACTER_ADDED', id: string, characterId: string }
export type AdventureCharacterRemoved = { type: 'ADVENTURE_CHARACTER_REMOVED', id: string, characterId: string }
export type AdventureGetEvents = { type: 'ADVENTURE_GET_EVENTS', id: string, events: AdventureEvent[], paged: boolean, done: boolean }
export type AdventureSetEventsLoading = { type: 'ADVENTURE_SET_EVENTS_LOADING', id: string, loading: boolean }
export type AdventureReceiveNewEvent = { type: 'ADVENTURE_RECEIVE_NEW_EVENT', id: string, event: AdventureEvent }
export type AdventureSetCommunalDice = { type: 'ADVENTURE_SET_COMMUNAL_DICE', id: string, communalDice: CommunalDice }
export type AdventureSetRolling = { type: 'ADVENTURE_SET_ROLLING', id: string, rolling: boolean }

export type AdventureAction = AdventuresLoaded | AdventuresLoadError | AdventureCreated | AdventureUpdateName
    | AdventureUpdateContentSharing | AdventureUpdateInvite | AdventureUpdatePublicNotes | AdventureUpdatePrivateNotes
    | AdventurePlayerJoined | AdventurePlayerLeft | AdventureCharacterAdded | AdventureCharacterRemoved
    | AdventureGetEvents | AdventureSetEventsLoading | AdventureReceiveNewEvent | AdventureSetCommunalDice
    | AdventureSetRolling;

const getAdventure = (id: string): AppThunkAction<AdventuresLoaded | ToastAction> => 
    async (dispatch, getState) => {
        try {
            const response = await ApiDefaultState.adventures.getAdventure(id);
            if (response.status == 'Success') {
                dispatch({ type: 'ADVENTURES_LOADED', adventures: [response.payload] });
            }
            else {
                ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
            }
        }
        catch (e) {
            console.error(e);
            UniversalActions.navToError(dispatch);
        }
    };

export const AdventureActions = Object.freeze({
    getAdventure,

    getAdventures: (): AppThunkAction<AdventuresLoaded | AdventuresLoadError | ToastAction> =>
        async (dispatch, getState) => {
            try {
                const response = await ApiDefaultState.adventures.getUserAdventures(true);
                if (response.status == 'Success') {
                    dispatch({ type: 'ADVENTURES_LOADED', adventures: response.payload });
                }
                else {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                }
            }
            catch(e) {
                console.error(e);
                dispatch({ type: 'ADVENTURES_LOAD_ERROR' });
                ToastDispatchables.toast(new ErrorToast("Error loading adventures."), dispatch);
            }
        },

    createAdventure: (successCallback: (id: string) => unknown): AppThunkAction<AdventureCreated | ToastAction> =>
        async (dispatch, getState) => {
            try {
                const response = await ApiDefaultState.adventures.createAdventure();
                if (response.status == 'Success') {
                    dispatch({ type: 'ADVENTURE_CREATED',  adventure: response.payload });
                    successCallback(response.payload.id);
                }
                else {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                }
            }
            catch(e) {
                console.error(e);
                UniversalActions.navToError(dispatch);
            }
        },

    updateName: (adventure: Adventure, name: string): AppThunkAction<AdventureUpdateName | ToastAction> =>
        async (dispatch, getState) => {
            const oldVersion = { ...adventure };
            const newVersion = { ...adventure, name };

            const undo = () => {
                dispatch({ type: 'ADVENTURE_UPDATE_NAME', id: adventure.id, name: oldVersion.name })
                ToastDispatchables.toast(new ErrorToast('Error updating name.'), dispatch);
            }
            dispatch({ type: 'ADVENTURE_UPDATE_NAME', id: adventure.id, name });
            try {
                const response = await ApiDefaultState.adventures.update(oldVersion, newVersion);
                if (response.status == 'Error') {
                    undo();
                }
            }
            catch (e) {
                undo();
                console.error(e);
            }
        },

    setPublicNotes: (adventure: Adventure, publicNotes: string): AppThunkAction<AdventureUpdatePublicNotes | ToastAction> =>
        async (dispatch, getState) => {
            const newAdventure = {
                ...adventure,
                publicNotes
            }

            try {
                const response = await ApiDefaultState.adventures.update(adventure, newAdventure);
                if (response.status == 'Success') {
                    dispatch({ type: 'ADVENTURE_UPDATE_PUBLIC_NOTES', id: adventure.id, publicNotes });
                }
                else {
                    ToastDispatchables.toast(new ErrorToast("Adventure notes not updated."), dispatch);
                }
            }
            catch (e) {
                ToastDispatchables.toast(new ErrorToast("Error updating notes."), dispatch);
            }
        },

    setPrivateNotes: (adventure: Adventure, privateNotes: string): AppThunkAction<AdventureUpdatePrivateNotes | ToastAction> =>
        async (dispatch, getState) => {
            dispatch({ type: 'ADVENTURE_UPDATE_PRIVATE_NOTES', id: adventure.id, privateNotes });

            const newAdventure = {
                ...adventure,
                privateNotes
            }
            try {
                const response = await ApiDefaultState.adventures.update(adventure, newAdventure);
                if (response.status == 'Error') {
                    dispatch({ type: 'ADVENTURE_UPDATE_PRIVATE_NOTES', id: adventure.id, privateNotes: adventure.privateNotes });
                    ToastDispatchables.toast(new ErrorToast("Adventure notes not updated."), dispatch);
                }
            }
            catch (e) {
                dispatch({ type: 'ADVENTURE_UPDATE_PRIVATE_NOTES', id: adventure.id, privateNotes: adventure.privateNotes });
                ToastDispatchables.toast(new ErrorToast("Error updating notes."), dispatch);
            }
        },

    setContentSharing: (adventure: Adventure, allowContentSharing: boolean): AppThunkAction<AdventureUpdateContentSharing | ToastAction> =>
        async (dispatch, getState) => {
            const oldVersion = { ...adventure };
            const newVersion = { ...adventure, allowContentSharing };

            const undo = () => {
                dispatch({ type: 'ADVENTURE_UPDATE_CONTENT_SHARING', id: adventure.id, allowContentSharing: oldVersion.allowsSharedContent });
                ToastDispatchables.toast(new ErrorToast(`Error ${allowContentSharing ? 'enabling' : 'disabling'} content sharing`), dispatch);
            }
            dispatch({ type: 'ADVENTURE_UPDATE_CONTENT_SHARING', id: adventure.id, allowContentSharing });
            try {
                const response = await ApiDefaultState.adventures.update(oldVersion, newVersion);
                if (response.status == 'Error') {
                    undo();
                }
            }
            catch (e) {
                undo();
                console.error(e);
            }
        },

    resetInvite: (adventure: Adventure): AppThunkAction<AdventureUpdateInvite | ToastAction> =>
        async (dispatch, getState) => {
            const onError = () => ToastDispatchables.toast(new ErrorToast("Error reseting invite link."), dispatch);
            try {
                const response = await ApiDefaultState.adventures.resetInvite(adventure.id);
                if (response.status == 'Success') {
                    dispatch({ type: 'ADVENTURE_UPDATE_INVITE', id: adventure.id, inviteId: response.payload });
                    ToastDispatchables.toast(new SuccessToast("Invite link updated."), dispatch);
                }
                else {
                    onError();
                }
            }
            catch (e) {
                console.error(e);
                onError();
            }
        },

    join: (inviteId: string, successCallback: (id: string) => unknown, errorCallback: () => unknown): AppThunkAction<ReturnType<typeof getAdventure> | ToastAction> =>
        async (dispatch, getState) => {
            const {player: user} = getState();
            try {
                const response = await ApiDefaultState.adventures.join(user.adventureConnectionId, inviteId);
                if (response.status == 'Success') {
                    await dispatch(getAdventure(response.payload));
                    successCallback(response.payload);
                }
                else {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                    errorCallback();
                }
            }
            catch (e) {
                console.error(e);
                ToastDispatchables.toast(new ErrorToast("Error joining adventure."), dispatch);
                errorCallback();
                throw e;
            }
        },

    playerJoined: (id: string, playerId: string): AppThunkAction<ReturnType<typeof UserActions.getUsersByIds> | AdventurePlayerJoined | ToastAction> =>
        async (dispatch, getState) => {
            await dispatch(UserActions.getUsersByIds(playerId));
            dispatch({ type: 'ADVENTURE_PLAYER_JOINED', id, playerId });

            const { players: users } = getState().player;
            const player = users.find(p => p.id == playerId);
            if (player != null) {
                ToastDispatchables.toast(new InfoToast(`${player.displayName} joined the adventure.`), dispatch);
            }
        },

    leave: (id: string, successCallback: () => unknown): AppThunkAction<ToastAction> =>
        async (dispatch, getState) => {
            const {player: user} = getState();
            try {
                const response = await ApiDefaultState.adventures.leave(user.adventureConnectionId, id);
                if (response.status == 'Success') {
                    successCallback();
                }
                else {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                }
            }
            catch (e) {
                console.error(e);
                ToastDispatchables.toast(new ErrorToast('Error leaving adventure.'), dispatch);
                throw e;
            }
        },

    playerLeft: (id: string, playerId: string): AppThunkAction<AdventurePlayerLeft | ToastAction> =>
        (dispatch, getState) => {
            dispatch({ type: 'ADVENTURE_PLAYER_LEFT', id, playerId });

            const { players: users } = getState().player;
            const player = users.find(p => p.id == playerId);
            if (player != null) {
                ToastDispatchables.toast(new InfoToast(`${player.displayName} left the adventure.`), dispatch);
            }
        },

    receiveCharacterAdded: (id: string, characterId: string): AppThunkAction<AdventureCharacterAdded> =>
        (dispatch) => dispatch({ type: 'ADVENTURE_CHARACTER_ADDED', id, characterId }),

    receivedCharacterRemoved: (id: string, characterId: string): AppThunkAction<AdventureCharacterRemoved> =>
        (dispatch) => dispatch({ type: 'ADVENTURE_CHARACTER_REMOVED', id, characterId }),

    addCharacterToAdventure: (adventure: Adventure, character: CharacterInitializer): AppThunkAction<AdventureCharacterAdded | ToastAction> =>
        async (dispatch, getState) => {
            const state = getState();
            const connectionId = state.player.adventureConnectionId;
            try {
                const response = await ApiDefaultState.adventures.addCharacterToAdventure(connectionId, adventure.id, character.id);
                if (response.status == 'Error') {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                }
                else {
                    dispatch({ type: 'ADVENTURE_CHARACTER_ADDED', id: adventure.id, characterId: character.id });
                }
            }
            catch (e) {
                console.error(e);
                ToastDispatchables.toast(new ErrorToast(`Error adding ${character.name} from ${adventure.name}.`), dispatch);
                throw e;
            }
        },

    removeCharacterFromAdventure: (adventure: Adventure, character: CharacterInitializer): AppThunkAction<AdventureCharacterRemoved | ToastAction> =>
        async (dispatch, getState) => {
            const state = getState();
            const connectionId = state.player.adventureConnectionId;
            try {
                const response = await ApiDefaultState.adventures.removeCharacterFromAdventure(connectionId, adventure.id, character.id);
                if (response.status == 'Error') {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                }
                else {
                    dispatch({ type: 'ADVENTURE_CHARACTER_REMOVED', id: adventure.id, characterId: character.id });
                }
            }
            catch (e) {
                console.error(e);
                ToastDispatchables.toast(new ErrorToast(`Error removing ${character.name} from ${adventure.name}.`), dispatch);
                throw e;
            }
        },

    deactivate: (id: string): AppThunkAction<ToastAction> => 
        async (dispatch, getState) => {
            try {
                const response = await ApiDefaultState.adventures.deactivate(id);
                if (response.status == 'Error') {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                }
            }
            catch (e) {
                console.error(e);
                ToastDispatchables.toast(new ErrorToast("Error deactivating adventure."), dispatch);
                throw e;
            }
        },

    getAdventureEvents: (id: string, skip?: number, take?: number): AppThunkAction<AdventureGetEvents | AdventureSetEventsLoading | ToastAction> =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: 'ADVENTURE_SET_EVENTS_LOADING', id, loading: true });
                const response = await ApiDefaultState.adventures.getAdventureEvents(id, skip, take);
                if (response.status == 'Error') {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                    dispatch({ type: 'ADVENTURE_SET_EVENTS_LOADING', id, loading: false });
                }
                else {
                    const paged = skip != undefined || take != undefined;
                    const done = take == undefined || response.payload.length < take;
                    dispatch({ type: 'ADVENTURE_GET_EVENTS', id, events: response.payload, paged, done });
                }
            }
            catch (e) {
                console.error(e);
                ToastDispatchables.toast(new ErrorToast("Error retrieving adventure events."), dispatch);
                throw e;
            }
        },

    pushNewEvent: (id: string, event: AdventureEvent): AppThunkAction<AdventureReceiveNewEvent | ToastAction> =>
        (dispatch, getState) => {
            const { adventure: adventureState, player: user, character, library: entities } = getState();
            if (user.me == null) {
                ToastDispatchables.toast(new ErrorToast("Received an event for an unauthenticated user."), dispatch);
                return;
            }
            const adventure = adventureState.adventures.first(a => a.id == id);
            if (adventure == null && adventureState.loaded) {
                ToastDispatchables.toast(new ErrorToast("Received an event for a non-existent adventure."), dispatch);
                return;
            }
            else if (adventure == null) {
                return;
            }
            dispatch({ type: 'ADVENTURE_RECEIVE_NEW_EVENT', id, event });
            const props = {
                adventure,
                event,
                players: [user.me, ...user.players],
                characters: [...character.characters, ...character.otherPlayerCharacters],
                entities
            }
            const adventureEvent = AdventureEventComponent(props);
            ToastDispatchables.toast(new InfoToast(adventureEvent), dispatch);
        },

    setCommunalDice: (id: string, communalDice: CommunalDice): AppThunkAction<AdventureSetCommunalDice | ToastAction> =>
        async (dispatch, getState) => {
            const state = getState();
            const { player: user } = state;
            const adventureSet = new SortedSet(state.adventure.adventures);
            const adventure = adventureSet.get(id);
            if (adventure == null) {
                ToastDispatchables.toast(new ErrorToast("Adventure not found!"), dispatch);
                return;
            }

            const undo = () => {
                dispatch({ type: 'ADVENTURE_SET_COMMUNAL_DICE', id, communalDice: adventure.communalDice });
                ToastDispatchables.toast(new ErrorToast("Error setting communal dice."), dispatch);
            }
            try {
                dispatch({ type: 'ADVENTURE_SET_COMMUNAL_DICE', id, communalDice });
                const response = await ApiDefaultState.adventures.setCommunalDice(user.adventureConnectionId, id, communalDice);
                if (response.status == 'Error') {
                    undo();
                }
            }
            catch (e) {
                console.error(e);
                undo();
            }
        },

    rollCommunalDice: (id: string): AppThunkAction<AdventureSetCommunalDice | AdventureSetRolling | ToastAction> => 
        async (dispatch, getState) => {
            const state = getState();
            const { player: user } = state;
            const adventureSet = new SortedSet(state.adventure.adventures);
            const adventure = adventureSet.get(id);
            if (adventure == null) {
                ToastDispatchables.toast(new ErrorToast("Adventure not found!"), dispatch);
                return;
            }

            const undo = () => {
                dispatch({ type: 'ADVENTURE_SET_COMMUNAL_DICE', id, communalDice: adventure.communalDice });
                ToastDispatchables.toast(new ErrorToast("Error rolling communal dice."), dispatch);
            }
            try {
                dispatch({ type: 'ADVENTURE_SET_ROLLING', id, rolling: true });
                const response = await ApiDefaultState.adventures.rollCommunalDice(user.adventureConnectionId, id);
                if (response.status == 'Error') {
                    undo();
                    return;
                }
                dispatch({ type: 'ADVENTURE_SET_COMMUNAL_DICE', id, communalDice: response.payload });
            }
            catch (e) {
                console.error(e);
                undo();
            }
            finally {
                dispatch({ type: 'ADVENTURE_SET_ROLLING', id, rolling: false });
            }
        },

    receiveCommunalDice: (id: string, communalDice: CommunalDice): AppThunkAction<AdventureSetCommunalDice | SheetSetCommunalRolls> =>
        (dispatch) => dispatch({ type: 'ADVENTURE_SET_COMMUNAL_DICE', id, communalDice }),

});