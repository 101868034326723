import { ItemFilter } from "../../../../../entities/filters/ItemFilter";
import { RollType } from "../../../../../entities/rolls/Roll";
import {SheetFilterItems, SheetSetHand, SheetSet, SheetSetCommunalRolls, SheetSetPreviewItemInstanceId, SheetSetPreviewSlotId, SheetSetRollSkill, SheetShowItemAdder, SheetClearRollSkill } from "../actions/SheetToolActions"
import { SheetState } from "../Sheet.State"

const setSheet = (state: SheetState, action: SheetSet): SheetState => ({
    ...state, ...action.character
});

const previewItem = (state: SheetState, action: SheetSetPreviewItemInstanceId): SheetState => ({
    ...state, previewItemInstanceId: action.instanceId
});

const previewSlot = (state: SheetState, action: SheetSetPreviewSlotId): SheetState => ({
    ...state, previewSlotId: action.slotId, previewSlotItemInstanceId: action.slotItemInstanceId
})

const showItemAdder = (state: SheetState, action: SheetShowItemAdder): SheetState => ({
    ...state,
    showItemAdder: true,
    addToContainerId: action.addToContainerId
});

const hideItemAdder = (state: SheetState): SheetState => ({
    ...state, showItemAdder: false, addToContainerId: undefined, itemFilter: ItemFilter.GetDefaultInitializer()
});

const filterItems = (state: SheetState, action: SheetFilterItems): SheetState => ({
    ...state, itemFilter: action.filter
});

const setHand = (state: SheetState, action: SheetSetHand): SheetState => ({
    ...state,
    currentDice: {
        ...state.currentDice,
        actionDice: action.hand.actionDice,
        extraDice: action.hand.extraDice,
    }
});

const resetHand = (state: SheetState): SheetState => ({
    ...state,
    currentDice: {
        rollType: RollType.Normal,
        actionDice: [],
        extraDice: [],
    }
});

const setCommunalDice = (state: SheetState, action: SheetSetCommunalRolls): SheetState => ({
    ...state,
    communalDice: [...action.communalDice]
})

const setRollSkill = (state: SheetState, action: SheetSetRollSkill): SheetState => ({
    ...state,
    currentDice: {
        ...state.currentDice,
        skillId: action.skillId
    }
})

const clearRollSkill = (state: SheetState): SheetState => ({
    ...state,
    currentDice: {
        ...state.currentDice,
        skillId: undefined
    }
})

export const SheetToolReducers = {
    setSheet,
    previewItem,
    previewSlot,
    showItemAdder,
    hideItemAdder,
    filterItems,
    setHand,
    resetHand,
    setCommunalDice,
    setRollSkill,
    clearRollSkill,
}