import { Action, Reducer } from "redux";
import { CharacterInitializer } from "../../../../entities/characters/Character";
import { SortedSet } from "../../../../entities/data-structures/SortedSet";
import { CharacterAction, CharacterReceivePlayerRoll, CharacterSetPlayerRolling } from "./CharacterStore.Actions";
import { CharacterDefaultState, CharacterState } from "./CharacterStore.State";
import { AdventureCharacterAdded, AdventureCharacterRemoved } from "../../adventures/AdventureStore.Actions";
import { RollType } from "../../../../entities/rolls/Roll";

export const CharacterReducer: Reducer<CharacterState> = (
    state: CharacterState | undefined,
    incomingAction: Action
): CharacterState => {
    if (state == undefined) {
        return CharacterDefaultState;
    }
    const action = incomingAction as CharacterAction | AdventureCharacterAdded | AdventureCharacterRemoved;
    const characters: SortedSet<CharacterInitializer> = new SortedSet(state.characters.map(c => {
        const { deleting, ...character } = { ...c };
        return character;
    }));
    switch (action.type) {
        case 'CHARACTERS_LOADED':
            return { ...state, loaded: true, characters: ConvertToDeletableCharacters(action.characters) };
        case 'CHARACTERS_UNOWNED_LOADED':
            return { ...state, otherPlayerCharacters: [...state.otherPlayerCharacters, ...ConvertToDeletableCharacters(action.characters)].unique(c => c.id) }
        case 'CHARACTER_UNOWNED_RECEIVED':
            return { ...state, otherPlayerCharacters: [...state.otherPlayerCharacters, {...action.character, deleting: false}].unique(c => c.id)}
        case 'CHARACTER_ADD_NEW':
            characters.add(action.character);
            return { ...state, characters: ConvertToDeletableCharacters(characters.collection) };
        case 'CHARACTER_UPDATED':
            characters.update(action.character);
            return { ...state, characters: ConvertToDeletableCharacters(characters.collection) };
        case 'CHARACTER_DELETING':
            return { ...state, characters: state.characters.map(c => ({ ...c, deleting: c.id == action.id ? !action.undo : c.deleting })) };
        case 'CHARACTER_DELETED':
            return { ...state, characters: state.characters.filter(c => c.id != action.id) };

        case 'CHARACTER_SET_PLAYER_ROLLING': return setPlayerRolling(state, action);
        case 'CHARACTER_RECEIVE_PLAYER_ROLL': return receivePlayerRoll(state, action);
        
        case 'ADVENTURE_CHARACTER_ADDED': return assignCharacterToAdventure(state, action);
        case 'ADVENTURE_CHARACTER_REMOVED': return removeCharacterFromAdventure(state, action);

        default: return { ...state };
    }
};

const ConvertToDeletableCharacters = (characters: CharacterInitializer[], deleting = false) => characters.map(c => ({ ...c, deleting }));

const assignCharacterToAdventure = (state: CharacterState, action: AdventureCharacterAdded): CharacterState => {
    const character = state.characters.find(c => c.id == action.characterId);
    if (character == null) {
        return assignOtherCharacterToAdventure(state, action);
    }
    character.assignedAdventureId = action.id;
    return {
        ...state,
        characters: [...state.characters]
    }
}

const assignOtherCharacterToAdventure = (state: CharacterState, action: AdventureCharacterAdded): CharacterState => {
    const character = state.otherPlayerCharacters.find(c => c.id == action.characterId);
    if (character == null) {
        return state;
    }
    character.assignedAdventureId = action.id;
    return { 
        ...state,
        otherPlayerCharacters: [...state.otherPlayerCharacters]
    };
}

const removeCharacterFromAdventure = (state: CharacterState, action: AdventureCharacterRemoved): CharacterState => {
    const character = state.characters.find(c => c.id == action.characterId);
    if (character == null) {
        return removeOtherCharacterFromAdventure(state, action);
    }
    delete character.assignedAdventureId;
    return {
        ...state,
        characters: [...state.characters]
    };
}

const removeOtherCharacterFromAdventure = (state: CharacterState, action: AdventureCharacterRemoved): CharacterState => {
    const character = state.characters.find(c => c.id == action.characterId);
    if (character == null) {
        return state;
    }
    delete character.assignedAdventureId;
    return {
        ...state,
        otherPlayerCharacters: [...state.otherPlayerCharacters]
    };
}

const setPlayerRolling = (state: CharacterState, action: CharacterSetPlayerRolling): CharacterState => {
    const { characterId, request, response } = action;
    const character = state.characters.find(c => c.id == characterId);
    if (character == null) {
        return setOtherPlayerRolling(state, action);
    }
    character.currentDice = {
        ...character.currentDice,
        actionDice: response.actionDice,
        extraDice: response.extraDice,
        skillId: request?.skillId ?? undefined,
    }
    character.rollRequest = action.request ?? undefined;
    return {
        ...state,
        characters: [...state.characters]
    };
}

const setOtherPlayerRolling = (state: CharacterState, action: CharacterSetPlayerRolling): CharacterState => {
    const { characterId, request, response } = action;
    const character = state.otherPlayerCharacters.find(c => c.id == characterId);
    if (character == null) {
        return state;
    }
    character.currentDice = {
        ...character.currentDice,
        actionDice: response.actionDice,
        extraDice: response.extraDice,
        skillId: request?.skillId ?? undefined,
    }
    character.rollRequest = action.request ?? undefined;
    return {
        ...state,
        characters: [...state.characters]
    };
}

const receivePlayerRoll = (state: CharacterState, action: CharacterReceivePlayerRoll): CharacterState => {
    const { characterId, response } = action;
    const character = state.characters.find(c => c.id == characterId);
    if (character == null) {
        return receiveOtherPlayerRoll(state, action);
    }
    delete character.rollRequest;
    return {
        ...state,
        characters: [...state.characters]
    }
}

const receiveOtherPlayerRoll = (state: CharacterState, action: CharacterReceivePlayerRoll): CharacterState => {
    const { characterId, response } = action;
    const character = state.otherPlayerCharacters.find(c => c.id == characterId);
    if (character == null) {
        return state;
    }
    delete character.rollRequest;
    return { ...state, characters: [...state.characters] };
}