import { ApiConfig, ApiConfigActions } from "../../config/ApiConfig";
import { StandardActions } from "../../config/StandardActions";
import { LibraryApiConfig } from "./LibraryApiConfig";

const ExtendedActions: ApiConfigActions = {
    'createWeapon': {
        route: '/Commands/CreateWeapon',
        method: 'POST'
    },
    'patchWeapon': {
        route: '/Commands/:id/PatchWeapon',
        method: 'PATCH'
    },
    'updateWeapon': {
        route: '/Commands/:id/UpdateWeapon',
        method: 'PUT'
    },

    'createMod': {
        route: '/Commands/CreateMod',
        method: 'POST'
    },
    'patchMod': {
        route: '/Commands/:id/PatchMod',
        method: 'PATCH'
    },
    'updateMod': {
        route: '/Commands/:id/UpdateMod',
        method: 'PUT'
    },

    'createAmmo': {
        route: '/Commands/CreateAmmo',
        method: 'POST'
    },
    'patchAmmo': {
        route: '/Commands/:id/PatchAmmo',
        method: 'PATCH'
    },
    'updateAmmo': {
        route: '/Commands/:id/UpdateAmmo',
        method: 'PUT'
    },

    'createSpell': {
        route: '/Commands/CreateSpell',
        method: 'POST'
    },
    'patchSpell': {
        route: '/Commands/:id/PatchSpell',
        method: 'PATCH'
    },
    'updateSpell': {
        route: '/Commands/:id/UpdateSpell',
        method: 'PUT'
    },
};

export const ItemApiConfig = new ApiConfig({
    ...LibraryApiConfig,
    apiPath: '/api/v1.0/Item',
    actions: {
        ...StandardActions,
        ...ExtendedActions
    }
});