import './roll-tool.scss';
import React, { useState } from "react";
import { Character } from '../../../../../entities/characters/Character';
import { ApplicationState } from '../../../../../store/stores/ApplicationState';
import { RollIcon } from '../../../../icons/CharacterIcons';
import { BreakdownWindow } from '../../../../theming/breakdown-window';
import { CommunalRolls } from './CommunalRolls';
import { RollActionPicker } from './RollActionPicker';
import { ThemedButton } from '../../../../inputs/buttons/ThemedButton';
import { SheetActions } from '../../../../../store/stores/characters/sheet/actions/Sheet.Actions';
import { ActionDiceCount, ActionRollRequest, BigStraight, Bust, DieFace, Flush, FullHouse, Hand, HandType, HandTypeName, HandTypeValue, Jackpot, Pair, Quad, RerollableIndex, RerollableIndices, RollType, SmallStraight, Triple, TwoPair } from '../../../../../entities/rolls/Roll';
import { Die } from '../../../../figures/Die';
import { ThemedRadio } from '../../../../inputs/radio/ThemedRadio';
import { Loader } from '../../../../theming/loader/Loader';
import { CharacterActions } from '../../../../../store/stores/collection/characters/CharacterStore.Actions';
import { useAppDispatch, useAppSelector } from '../../../../../store/configureStore';

type Props = {
    character: Character
}

type RollTypeOption = {
    name: string,
    display: string,
    value: RollType,
    rerollMod: number;
}

type RollStage = "Initial" | "Reroll" | "Declaration";

const AdvantageRollOption = {
    name: "Advantage",
    display: "Advantage",
    value: RollType.Advantage,
    rerollMod: 1,
}

const NormalRollOption = {
    name: "Normal",
    display: "Normal",
    value: RollType.Normal,
    rerollMod: 0,
}

const DisadvantageRollOption = {
    name: "Disadvantage",
    display: "Disadvantage",
    value: RollType.Disadvantage,
    rerollMod: -1,
};

const RollTypeOptions = [
    AdvantageRollOption,
    NormalRollOption,
    DisadvantageRollOption
];

export const RollTool: React.FC<Props> = (props: Props) => {
    const { character } = props;
    const dispatch = useAppDispatch();
    const {adventures, loaded} = useAppSelector(app => app.adventure)
    const { currentDice, rollRequest } = useAppSelector(app => app.sheet);
    const [rollType, setRollType] = useState<RollTypeOption>(NormalRollOption);
    const [showWindow, toggleWindow] = useState<boolean>(false);
    const [rerolls, setRerolls] = useState<RerollableIndices>([]);
    const [rollStage, setRollStage] = useState<RollStage>("Initial");
    const [declaration, setDeclaration] = useState<HandTypeName | null>(null);

    const adventure = adventures.first(a => a.characterIds.contains(character.id));

    const skill = character.skills.collection.find(s => s.id == currentDice.skillId);
    const skillRoller = skill?.roller;
    
    let maxRerolls = skillRoller?.rerolls ?? 0;
    maxRerolls += rollType.rerollMod;
    maxRerolls = Math.max(maxRerolls, 0);

    const rerollsRemaining = maxRerolls - rerolls.length;
    const extraDiceCount = skillRoller?.extraRolls ?? 0;
    const rerollPickDisabled = maxRerolls == 0 || rollStage == "Declaration";
    const activelyRolling = rollRequest != null;
    const onRerollPhase = currentDice.actionDice.any();

    const onRoll = () => {
        dispatch(SheetActions.rollHand(character.id, {...ActionRollRequest}));
        setRerolls([]);
        setRollStage("Reroll");
    }

    const onReroll = () => {
        if (skill == null) {
            return;
        }
        const request = {
            skillId: skill.id,
            rollType: rollType.value,
            rerollIndices: rerolls,
        }
        dispatch(SheetActions.rollHand(character.id, request));
        setRerolls([]);
        setRollStage("Declaration");
    }

    const reset = () => {
        dispatch(SheetActions.resetHand());
        setRerolls([]);
        setRollStage("Initial");
    }

    const onOpen = () => {
        reset();
        toggleWindow(true);
    }

    const createRerollToggle = (index: RerollableIndex) => {
        return () => {
            if (rerollPickDisabled) {
                return;
            }

            if (rerolls.contains(index)) {
                setRerolls([...rerolls].filter(r => r != index) as RerollableIndices);
            }
            else if (rerolls.length < maxRerolls) {
                setRerolls([...rerolls, index] as RerollableIndices);
            }
        }
    }

    const changeRollType = (selection: RollTypeOption) => {
        setRerolls([]);
        setRollType(selection);
    }

    const createDeclarationMethod = (handType: HandType) => {
        return () => {
            setDeclaration(handType.name);
            dispatch(CharacterActions.declareHand(character.id, handType.value));
        }
    }

    const rollWindowHeading = adventure == null
        ? <h1>Loading Adventure</h1>
        : <h1>Roll{skill && (` ${skill.name}`)}!</h1>;

    if (adventure == null && loaded) {
        return null;
    }

    let finalHand: Hand | undefined = undefined;
    if (adventure != null) {
        finalHand = new Hand(...adventure.communalDice, ...currentDice.actionDice, ...currentDice.extraDice);
    }
    return (
        <>
            <ThemedButton className="roll-tool" onClick={onOpen}>
                <RollIcon /> Roll!
            </ThemedButton>
            <BreakdownWindow className="roll-tool-window" visible={showWindow} close={() => toggleWindow(false)}
                heading={rollWindowHeading}>
                
                {adventure == null && (
                    <Loader />
                )}

                {adventure != null && <>
                    <div className='roll-tool-rolls'>
                        <CommunalRolls adventure={adventure} />
                        <div className="action-rolls">
                            <label className="standout">Action Dice</label>

                            {!currentDice.actionDice.any() && [...Array(ActionDiceCount)].map((_, index) => (
                                <Die key={index} rolling={activelyRolling} big>?</Die>
                            ))}
                            {currentDice.actionDice.map((die, index) => (
                                <RerollableDie key={index} faceValue={die} selected={rerolls.contains(index)} rolling={rollRequest?.rerollIndices.contains(index) ?? false}
                                    toggleForReroll={createRerollToggle(index as RerollableIndex)} disabled={rerollPickDisabled} />
                            ))}

                            {rollStage == "Initial" && (
                                <ThemedButton className="reroll-button" onClick={onRoll}>
                                    Roll
                                </ThemedButton>
                            )}
                        </div>
                        
                        {(currentDice.extraDice.any() || extraDiceCount > 0) &&
                            <div className="extra-rolls">
                                <label className="standout">Extra Dice</label>

                                {!currentDice.extraDice.any() && [...Array(extraDiceCount)].map((_, index) => (
                                    <Die key={index} rolling={activelyRolling} big>?</Die>
                                ))}

                                {currentDice.extraDice.any() && currentDice.extraDice.map((roll, index) => (
                                    <Die key={index} rolling={activelyRolling} big>{roll}</Die>
                                ))}
                            </div>
                        }
                    </div>
                    {onRerollPhase && !activelyRolling && (<>
                        <div>
                            <ThemedRadio className="text-options" options={RollTypeOptions} selected={rollType} onChange={changeRollType} />
                        </div> 
                        <RollActionPicker character={character} disabled={rollStage != "Reroll"} />
                    </>)}                    

                    {rollStage == "Reroll" && (
                        <div className="reroll-controls">
                            {maxRerolls > 0 && skill != null && (
                                <>
                                    <div>Click on action dice above to reroll them.</div>
                                    <div>{rerollsRemaining} rerolls remaining.</div>
                                </>
                            )}
                            {maxRerolls <= 0 && skill != null && <div>No rerolls available.</div>}
                            
                            {skill != null && (
                                <div className="button-container">
                                    <ThemedButton onClick={onReroll} title={rerollPickDisabled ? 'No rerolls remaining' : undefined}>
                                        {rerolls.any() ? `Reroll ${rerolls.length} ${rerolls.length == 1 ? "die" : "dice"}` : "Keep (no rerolls)"}
                                    </ThemedButton>

                                    {extraDiceCount > 0 && <div>You'll also roll {extraDiceCount} extra {extraDiceCount != 1 ? 'dice' : 'die'}.</div>}
                                </div>
                            )}
                        </div>)}
                </>}

                {rollStage == "Declaration" && !activelyRolling && finalHand && declaration == null &&
                    <div className="possible-hands">
                        <label className="standout">Possible Hands</label>
                        <ThemedButton onClick={createDeclarationMethod(Bust)}>Bust</ThemedButton>
                        {finalHand.isPair && <ThemedButton onClick={createDeclarationMethod(Pair)}>Pair</ThemedButton>}
                        {finalHand.isTwoPair && <ThemedButton onClick={createDeclarationMethod(TwoPair)}>Two Pair</ThemedButton>}
                        {finalHand.isTriple && <ThemedButton onClick={createDeclarationMethod(Triple)}>Triple</ThemedButton>}
                        {finalHand.isSmallStraight && <ThemedButton onClick={createDeclarationMethod(SmallStraight)}>Small Straight</ThemedButton>}
                        {finalHand.isFlush && <ThemedButton onClick={createDeclarationMethod(Flush)}>Flush</ThemedButton>}
                        {finalHand.isFullHouse && <ThemedButton onClick={createDeclarationMethod(FullHouse)}>Full House</ThemedButton>}
                        {finalHand.isBigStraight && <ThemedButton onClick={createDeclarationMethod(BigStraight)}>Big Straight</ThemedButton>}
                        {finalHand.isQuad && <ThemedButton onClick={createDeclarationMethod(Quad)}>Quad</ThemedButton>}
                        {finalHand.isJackpot && <ThemedButton onClick={createDeclarationMethod(Jackpot)}>Jackpot!</ThemedButton>}
                    </div>
                }
                    
                {rollStage == "Declaration" && !activelyRolling && declaration != null &&
                    <>
                        <span className='standout'>Declared: {declaration}</span>
                        <br />
                        <ThemedButton onClick={reset}>
                            Reset
                        </ThemedButton>
                    </>
                }
            </BreakdownWindow>
        </>
    );
}

type RerollableProps = {
    faceValue: DieFace;
    selected: boolean;
    toggleForReroll: () => unknown;
    rolling: boolean;
    disabled: boolean;
 }

const RerollableDie = (props: RerollableProps) => {
    const { faceValue, selected, toggleForReroll, rolling, disabled } = props;
    return (
        <Die className={`interactable ${selected ? 'selected' : 'unselected'} ${disabled ? 'disabled' : 'enabled'}`} rolling={rolling} onClick={toggleForReroll} big>
            {faceValue}
        </Die>
    );
}