import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { LibraryActions } from "../../../store/stores/library/LibraryStore.Actions";
import { BookView } from "./BookView";
import { BookResults } from "./BookResults";
import { useAppDispatch, useAppSelector } from "../../../store/configureStore";

export const BookLibrary = () => {
    const dispatch = useAppDispatch();
    const { allBooksLoaded } = useAppSelector(app => app.library);

    useEffect(() => {
        if (!allBooksLoaded) {
            dispatch(LibraryActions.loadBooks());
        }
    }, [allBooksLoaded]);

    return (
        <Routes>
            <Route path=":name/*" element={<BookView />} />
            <Route path="*" element={<BookResults />} />
        </Routes>
    )
}