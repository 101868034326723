export type Kinship = 'Human'
    | 'Goblin';

export const Kinships: Kinship[] = [
    'Human',
    'Goblin',
];

export const KinshipIds = {
    'Human': '17c027c3-3f9c-4590-a914-34cce19a168a',
    'Goblin': 'cb382173-93fd-4d36-ba39-c8a06eb8067c',
}