import { configureStore, Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { AccountApiServerConfig } from '../apis/account/config/AccountApiServerConfig';
import { AdventureHub } from '../apis/account/Hub.Adventure';
import { createRootReducer } from './RootReducer';
import { ApplicationState } from './stores/ApplicationState';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

function configureAppStore(preloadedState: ApplicationState) {
    const reducer = createRootReducer();

    const store = configureStore({
        reducer,
        middleware: (getDefaultMiddleWare) => getDefaultMiddleWare().concat(AdventureHub.CreateMiddleware()),
        // middleware: () => new Tuple(thunk, ...AdventureHub.CreateMiddleware()),
        preloadedState,
    });

    const adventureHub = AdventureHub.CreateInstance(store, AccountApiServerConfig.AdventureHubConfig);
    setupHubs(adventureHub, store.dispatch);
    return store;
}

export type StoreDispatch = ReturnType<typeof configureAppStore>['dispatch'];
export type StoreGetState = ReturnType<typeof configureAppStore>['getState'];
export const useAppDispatch = useDispatch.withTypes<StoreDispatch>();
export const useAppSelector = useSelector.withTypes<ApplicationState>();

export default configureAppStore;

function setupHubs(adventureHub: AdventureHub, dispatch: Dispatch<UnknownAction>) {
    dispatch({ type: 'API_SET_HUB_ADVENTURE', adventureHub });
}