import { HubConnection, HubConnectionBuilder, LogLevel, RetryContext } from '@microsoft/signalr';
import { Dispatch, Middleware } from 'redux';
import { ErrorToast, InfoToast } from '../entities/toasts/Toasts';
import { ApplicationState, AppThunkAction } from '../store/stores/ApplicationState';
import { ToastDispatchables } from '../store/stores/toasts/Toasts.Actions';
import { AuthorizedWebService } from './AuthorizedWebService';
import { HubConfig } from "./config/HubConfig";

export type AppStore = { dispatch: Dispatch<any>, getState: () => ApplicationState };

/**
 * Provides basic functionality for connecting to and managing a web sockets connection.
 */
export abstract class BaseHub<TConfig extends HubConfig> extends AuthorizedWebService {
    protected constructor(store: AppStore, config: TConfig) {
        super();
        this.store = store;
        this.config = config;
    }
    private readonly store: AppStore;

    protected readonly config: TConfig;

    protected get dispatch(): Dispatch<any> {
        return this.store.dispatch;
    }

    protected getState(): ApplicationState {
        return this.store.getState();
    }

    private readonly createConnectionPromise = () => new Promise<HubConnection>((resolve, reject) => {
            this.connectionResolver = resolve;
            this.connectionRejector = reject;
    });
    protected connection: Promise<HubConnection> = this.createConnectionPromise();
    protected connectionResolver?: ((value: HubConnection | PromiseLike<HubConnection>) => void);
    protected connectionRejector?: ((reason?: any) => void);

    /**
     * Creates middleware for controlling hubs.
     * @param hub The hub to generate middleware for.
     * @returns An array of middlewares to handle incoming actions.
     */
    public static CreateMiddleware(): Middleware[] {
        return [
            ({ getState }) => (next) => (action: unknown) => {
                try {
                    BaseHub.Reduce(getState, action);
                }
                catch (e) {
                    console.error(e);
                }
                finally {
                    next(action ?? { type: "" }); //Somehow there is an undefined action getting dispatched
                }
            }
        ]
    }

    public static Reduce<T extends HubConfig>(getState: () => ApplicationState, incomingAction: unknown) {
        const action = incomingAction as HubAction<T>;
        switch (action.type) {
            case 'HUB_ON':
                action.hub.start();
                return;
            case 'HUB_OFF':
                action.hub.stop();
                return;
            case 'HUB_RESTART':
                action.hub.restart();
                return;
        }
    }

    /**
     * Creates a new websockets connection.
     * @returns A new hub connection to the configured endpoint.
     */
    private createConnection(): HubConnection {
        const connection = new HubConnectionBuilder()
            .withUrl(this.config.endpoint, {
                accessTokenFactory: async () => await this.getToken()
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
                    const retryTimeoutIndex = Math.min(retryContext.previousRetryCount, this.config.retryTimeouts.length - 1);
                    return this.config.retryTimeouts[retryTimeoutIndex];
                }
            })
            .configureLogging(LogLevel.Information)
            .build();
        connection.onreconnecting(this.onReconnecting.bind(this));
        connection.onclose(this.onClose.bind(this));
        return connection;
    }

    /**
     * Starts the hub service.
     */
    protected async start(): Promise<void> {
        try {
            this.connectionRejector && this.connectionRejector(`The ${this.config.name} connection is being restarted.`);
            this.connection = this.createConnectionPromise();
            const connection = this.createConnection();
            await connection.start();
            this.connectionResolver!(connection);
        }
        catch (e) {
            console.error(e);
            ToastDispatchables.toast(new ErrorToast("Unrecoverable error encountered while starting live updates."), this.dispatch);
        }
    }

    /**
     * Stops the hub service.
     */
    protected async stop(): Promise<void> {
        this.connectionRejector && this.connectionRejector(`The ${this.config.name} connection was terminated.`);
        (await this.connection).stop;
    }

    /**
     * Restarts the hub connection.
     */
    protected async restart(): Promise<void> {
        await this.stop();
        await this.start();
    }

    /**
     * Handles a reconnect event.
     * @param error The error, if it exists.
     */
    protected onReconnecting(error?: Error): void {
        if (error) {
            console.error(error);
        }
        ToastDispatchables.toast(new ErrorToast("Live updates lost, attempting to reconnect. Refreshing might fix the issue."), this.dispatch);
    }

    /**
     * Handles a close event.
     * @param error The error, if it exists.
     */
    protected onClose(error?: Error): void {
        if (error) {
            console.error(error);
            ToastDispatchables.toast(new ErrorToast("Live updates terminated unexpectedly."), this.dispatch);
        }
        else {
            ToastDispatchables.toast(new InfoToast("Live updates have ended."), this.dispatch);
        }
    }

    protected invokeDispatch(fn: (...args: any[]) => void): (...args: any[]) => void {
        if (this.dispatch == undefined) {
            throw new Error("Dispatch not initialized.");
        }
        return (...args) => this.dispatch(fn(...args));
    }
}

type HubOn<T extends HubConfig> = { type: 'HUB_ON', hub: BaseHub<T> };
type HubOff<T extends HubConfig> = { type: 'HUB_OFF', hub: BaseHub<T> };
type HubRestart<T extends HubConfig> = { type: 'HUB_RESTART', hub: BaseHub<T> };
export type HubAction<T extends HubConfig> = HubOn<T> | HubOff<T> | HubRestart<T>;
export const HubActions = {
    hubOn: <T extends HubConfig>(hub: BaseHub<T>): AppThunkAction<HubOn<T>> =>
        (dispatch) => dispatch({ type: 'HUB_ON', hub }),
}