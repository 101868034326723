import React from "react";
import { Character } from "../../../../../entities/characters/Character";
import { SheetActions } from "../../../../../store/stores/characters/sheet/actions/Sheet.Actions";
import { SingleSelect } from "../../../../inputs/selects/singleselect/SingleSelect";
import { SelectableOption } from "../../../../inputs/selects/SelectableOption";
import { useAppDispatch, useAppSelector } from "../../../../../store/configureStore";

type Props = { character: Character, disabled?: boolean }

export const RollActionPicker: React.FC<Props> = (props: Props) => {
    const { character, disabled } = props;
    const dispatch = useAppDispatch();
    const {skillId} = useAppSelector(app => app.sheet.currentDice);

    const options: SelectableOption<string>[] = character.skills.collection.map(skill => ({
        value: skill.id,
        name: skill.name
    }))
    return (
        <div className="roll-action-picker">
            <label className="standout">
                Pick a Skill:
            </label>
            &nbsp;
            <SingleSelect options={options} onChange={(skillId: string) => dispatch(SheetActions.setRollSkill(skillId))} selection={skillId} disabled={disabled} />
        </div>
    );
}