import { Action, Reducer } from "redux";
import { SortedSet } from "../../../entities/data-structures/SortedSet";
import { UserAction } from "./PlayerStore.Actions";
import { PlayerDefaultState, PlayerState } from "./PlayerStore.State";

export const PlayerReducer: Reducer<PlayerState> = (state: PlayerState | undefined, incomingAction: Action) => {
    if (state == undefined) {
        return PlayerDefaultState;
    }
    const action = incomingAction as UserAction;
    switch (action.type) {
        case 'USER_SET':
            return { ...state, me: action.userAccount };
        case 'USER_SET_ADVENTURE_CONNECTION':
            return { ...state, adventureConnectionId: action.adventureConnectionId };
        case 'USERS_GET':
            const users = new SortedSet(state.players);
            const toAdd = action.users.filter(u => u.id != state.me?.id);
            users.addOrUpdateRange(...toAdd);
            return { ...state, players: [...users], loadingMe: false }
        case 'USER_CLEAR':
            return { ...state, me: null, loadingMe: false };
        default:
            return state;
    }
}