import * as JsonPatch from 'fast-json-patch';
import { Adventure, CommunalDice } from "../../entities/adventures/Adventure";
import { ApiResponse, SuccessApiResponse } from "../responses/ApiResponse";
import { AccountApi } from "./Api.Account";
import { AccountApiServerConfig } from "./config/AccountApiServerConfig";
import { DieFace } from '../../entities/rolls/Roll';
import { AdventureEvent } from '../../entities/adventures/AdventureEvent';
import { MaxSafeCSharpInt } from '../../entities/Utilities';

export class AdventureApi extends AccountApi<typeof AccountApiServerConfig.AdventureApiConfig> {
    /**
     * Gets a adventure.
     * @param adventureId The adventure's unique id.
     * @returns An api response with the requested adventure.
     */
    public async getAdventure(adventureId: string): Promise<ApiResponse<Adventure>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('get', { name: ':id', value: adventureId });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method
            });

            if (this.isServerError(response)) {
                throw new Error('Server error while retrieving adventure.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Gets all adventures for a user.
     * @param includeInactive If true, inactive adventures will be included in the request.
     * @returns An api response with all the user's adventures.
     */
    public async getUserAdventures(includeInactive: boolean = false): Promise<ApiResponse<Adventure[]>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('userAdventures', { name: ':includeInactive', value: includeInactive });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method
            });

            if (this.isServerError(response)) {
                throw new Error('Server error while retrieving user adventures.');
            }
            return this.generateResponse(response, []);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Creates a new adventure.
     * @returns The newly created adventure.
     */
    public async createAdventure(): Promise<ApiResponse<Adventure>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('create');
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method,
            });

            if (this.isServerError(response)) {
                throw new Error('Server error while creating adventure.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Detects the difference between two version of a adventure and submits a patch request with those changes.
     * @param oldVersion The old version of the adventure. 
     * @param newVersion The new version of the adventure.
     * @returns An updated version of the adventure.
     */
    public async update(oldVersion: Adventure, newVersion: Adventure): Promise<ApiResponse<Adventure>> {
        const operations = JsonPatch.compare(oldVersion, newVersion);
        if (!operations.any()) {
            return new SuccessApiResponse(oldVersion);
        }

        try {
            const { uri, method } = this.config.getUriAndMethod('update', { name: ':id', value: oldVersion.id });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method,
                body: JSON.stringify(operations)
            });

            if (this.isServerError(response)) {
                throw new Error('Server error while updating adventure.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Resets the invitation id for this adventure.
     * @param adventureId The adventure's unique id.
     * @returns The updated invitation id.
     */
    public async resetInvite(adventureId: string): Promise<ApiResponse<string>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('resetInvite', { name: ':id', value: adventureId });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method,
            });

            if (this.isServerError(response)) {
                throw new Error('Server error while reseting invite link.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Joins an adventure.
     * @param connectionId The adventure connection id used by this instance.
     * @param inviteId The adventure's unique invitation id.
     * @returns The adventure's unique id.
     */
    public async join(connectionId: string | null, inviteId: string): Promise<ApiResponse<string>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('joinAdventure', { name: ':inviteId', value: inviteId });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method,
                body: JSON.stringify({connectionId})
            });

            if (this.isServerError(response)) {
                throw new Error('Server error joining adventure.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Leaves an adventure.
     * @param connectionId The adventure connection id used by this instance.
     * @param id The adventure's unique identifier.
     */
    public async leave(connectionId: string | null, id: string): Promise<ApiResponse<void>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('leaveAdventure', { name: ':id', value: id });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method,
                body: JSON.stringify({connectionId})
            });

            if (this.isServerError(response)) {
                throw new Error('Server error leaving adventure.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Deactivates an adventure.
     * @param id The adventure's unique identifier.
     */
    public async deactivate(id: string): Promise<ApiResponse<void>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('deactivate', { name: ':id', value: id });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method
            });

            if (this.isServerError(response)) {
                throw new Error('Server error deactivating adventure.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Adds a character to an adventure.
     * @param connectionId The adventure connection id used by this instance.
     * @param id The adventure's unique identifier.
     * @param characterId The character's unique identifier.
     */
    public async addCharacterToAdventure(connectionId: string | null, id: string, characterId: string): Promise<ApiResponse<void>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('addCharacter', { name: ':id', value: id });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method,
                body: JSON.stringify({ connectionId, payload: characterId })
            });

            if (this.isServerError(response)){ 
                throw new Error('Server error adding character to adventure.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Removes a character from an adventure.
     * @param connectionId The adventure connection id used by this instance.
     * @param id The adventure's unique identifier.
     * @param characterId The character's unique identifier.
     */
    public async removeCharacterFromAdventure(connectionId: string | null, id: string, characterId: string): Promise<ApiResponse<void>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('removeCharacter', { name: ':id', value: id });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method,
                body: JSON.stringify({ connectionId, payload: characterId })
            });

            if (this.isServerError(response)){ 
                throw new Error('Server error removing character from adventure.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Retrieves all events for a given adventure. 
     * @param id 
     * @param skip The number of records to skip
     * @param take The maximum number of records to retrieve. Defaults to all records.
     * @returns Events in the adventure ignoring the first @param skip records and numbering no more than @param take records.
     */
    public async getAdventureEvents(id: string, skip: number = 0, take: number = MaxSafeCSharpInt): Promise<ApiResponse<AdventureEvent[]>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('getEvents', { name: ':id', value: id }, { name: ':skip', value: skip }, { name: ':take', value: take });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method
            });

            if (this.isServerError(response)) {
                throw new Error('Server error retrieving adventure events.');
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Sets an adventure's communal dice values.
     * @param connectionId The adventure connection id used by this instance.
     * @param id The adventure's unique identifier.
     * @param communalDice The communal dice values to set.
     */
    public async setCommunalDice(connectionId: string | null, id: string, communalDice: CommunalDice): Promise<ApiResponse<void>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('setCommunalDice', { name: ':id', value: id });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method,
                body: JSON.stringify({ connectionId, payload: communalDice })
            });

            if (this.isServerError(response)) {
                throw new Error("Server error setting communal dice.");
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Rolls the adventure's communal dice.
     * @param id The adventure's unique identifier.
     * @returns The rolled dice values.
     */
    public async rollCommunalDice(connectionId: string | null, id: string): Promise<ApiResponse<CommunalDice>> {
        try {
            const { uri, method } = this.config.getUriAndMethod('rollCommunalDice', { name: ':id', value: id });
            const token = await this.getToken();
            const response = await fetch(uri, {
                ...this.createDefaultRequestDetails(token),
                method,
                body: JSON.stringify({connectionId})
            });

            if (this.isServerError(response)) {
                throw new Error("Server error rolling communal dice.");
            }
            return this.generateResponse(response);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }
}