import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store/stores/ApplicationState";
import { useNavigate } from "react-router";

export const Profile: React.FC = () => {
    return (
        <main>
            <div className="contents">
            </div>
        </main>
    );
}