import { CommunalDice } from "../../../../entities/adventures/Adventure";
import { LocalCharacterInitializer } from "../../../../entities/characters/Character";
import { ItemFilter, ItemFilterInitializer } from "../../../../entities/filters/ItemFilter";
import { RollType } from "../../../../entities/rolls/Roll";
import { ApplicationStorage } from "../../../storage/AppStorage";

export type SheetState = LocalCharacterInitializer & {
    addToContainerId?: string;
    previewItemInstanceId?: string;
    previewSlotId?: string;
    previewSlotItemInstanceId?: string;
    showItemAdder: boolean;
    itemFilter: ItemFilterInitializer;
    communalDice: CommunalDice;
    enforceRules: boolean;
    pendingProperties: { [key: string]: boolean } //Can't use map because non-POJO
}

export const SheetDefaultState: SheetState = {
    id: '',
    ownerId: '',
    name: '',
    created: new Date().toISOString(),
    kinship: 'Human',
    level: 1,
    className: 'Adventurer',
    baseMovement: 6,
    movementAdjust: 0,
    money: 0,
    
    currentHealth: 0,
    currentWounds: 0,
    juiced: true,

    communalDice: [1, 1],

    currentDice: {
        rollType: RollType.Normal,
        actionDice: [],
        extraDice: [],
    },

    competencies: [],
    customCompetencies: [],
    conditions: [],
    skills: [],
    rhetorics: [],
    personalities: [],
    perks: [],

    bodySlots: [],
    bodyMods: [],

    held: [],
    worn: [],
    loose: [],

    minions: [],

    showItemAdder: false,
    itemFilter: ItemFilter.GetDefaultInitializer(),

    enforceRules: ApplicationStorage.enforceRules,

    pendingProperties: {},
};