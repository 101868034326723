import React from "react"
import { EnemyFilterInitializer } from "../../../entities/filters/EnemyFilter"
import { NameFilter } from "../../characters/items/filters/NameFilter";
import { TagsFilter } from "../../characters/items/filters/TagsFilter";

type Props = {
    filter: EnemyFilterInitializer;
    updateFilter: (filter: EnemyFilterInitializer) => unknown;
}

export const EnemyFilterComponent = (props: Props) => {
    const { filter, updateFilter } = props;
    return (
        <div className="filters">
            <NameFilter name={filter.name} onChange={(name) => updateFilter({ ...filter, name })} />
            <TagsFilter tags={filter.tags} onChange={(tags) => updateFilter({ ...filter, tags })} />
        </div>
    )
}