import { CharacterInitializer, CollectionsToReferences } from "../../../../entities/characters/Character";
import { HandTypeValue, RollRequest, RollResponse } from "../../../../entities/rolls/Roll";
import { ErrorToast } from "../../../../entities/toasts/Toasts";
import { CharacterEntityInitializers } from "../../../../services/CharacterService";
import { ApiDefaultState } from "../../api/ApiStore.State";
import { AppThunkAction } from "../../ApplicationState";
import { LibraryAction } from "../../library/LibraryStore.Actions";
import { ToastAction, ToastDispatchables } from "../../toasts/Toasts.Actions";

type CharactersLoaded = { type: 'CHARACTERS_LOADED', characters: CharacterInitializer[] }
type CharactersUnownedLoaded = { type: 'CHARACTERS_UNOWNED_LOADED', characters: CharacterInitializer[] }
type CharacterUnownedReceived = { type: 'CHARACTER_UNOWNED_RECEIVED', character: CharacterInitializer }
type CharacterAddNew = { type: 'CHARACTER_ADD_NEW', character: CharacterInitializer }
type CharacterDeleting = { type: 'CHARACTER_DELETING', id: string, undo?: boolean }
type CharacterDeleted = { type: 'CHARACTER_DELETED', id: string }
export type CharacterUpdated = { type: 'CHARACTER_UPDATED', character: CharacterInitializer }
export type CharacterSetPlayerRolling = { type: 'CHARACTER_SET_PLAYER_ROLLING', characterId: string, request: RollRequest | null, response: RollResponse }
export type CharacterReceivePlayerRoll = { type: 'CHARACTER_RECEIVE_PLAYER_ROLL', characterId: string, response: RollResponse }

export type CharacterAction = CharactersLoaded
    | CharactersUnownedLoaded
    | CharacterUnownedReceived
    | CharacterAddNew
    | CharacterUpdated
    | CharacterDeleting
    | CharacterDeleted
    | CharacterSetPlayerRolling
    | CharacterReceivePlayerRoll;

export const CharacterActions = {
    createNewCharacter: (successCallback: (id: string) => unknown): AppThunkAction<CharacterAddNew | ToastAction> =>
        async (dispatch, getState) => {
            try {
                const response = await ApiDefaultState.characters.createNewCharacter();
                if(response.status == 'Success') {
                    dispatch({ type: 'CHARACTER_ADD_NEW', character: response.payload });
                    successCallback(response.payload.id);
                }
            }
            catch (error) {
                console.error(error);
                ToastDispatchables.toast(new ErrorToast("Server error while creating character."), dispatch);
            }
        },
        
    getCharacters: (forceReload: boolean = false): AppThunkAction<CharactersLoaded | LibraryAction | ToastAction> => 
        async (dispatch, getState) => {
            const { character, library } = getState();
            if(!forceReload && character.characters.length > 0) {
                return;
            }
            try {
                const response = await ApiDefaultState.characterService.getAllCharactersWithEntities(CollectionsToReferences(library));
                if (response.status == 'Error') {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                    return;
                }

                dispatchLibraryActions(response.payload, dispatch);
                dispatch({ type: 'CHARACTERS_LOADED', characters: response.payload.characters });
            }
            catch (error) {
                console.error(error);
                ToastDispatchables.toast(new ErrorToast("Server error while loading characters."), dispatch);
            }
        },

    getCharactersByIds: (ids: string[]): AppThunkAction<CharactersUnownedLoaded | LibraryAction | ToastAction> =>
        async (dispatch, getState) => {
            const { library } = getState();
            try {
                const response = await ApiDefaultState.characterService.getCharactersByIdsWithEntities(CollectionsToReferences(library), ids);
                if (response.status == 'Error') {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                    return;
                }
                dispatchLibraryActions(response.payload, dispatch);
                dispatch({ type: 'CHARACTERS_UNOWNED_LOADED', characters: response.payload.characters });
            }
            catch (error) {
                console.error(error);
                ToastDispatchables.toast(new ErrorToast("Server error while loading characters."), dispatch);
            }
        },
    
    receiveUnownedCharacter: (character: CharacterInitializer): AppThunkAction<CharacterUnownedReceived> =>
        (dispatch) => dispatch({ type: 'CHARACTER_UNOWNED_RECEIVED', character }),

    deleteCharacter: (character: CharacterInitializer): AppThunkAction<CharacterDeleted | CharacterDeleting | ToastAction> =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: 'CHARACTER_DELETING', id: character.id });
                const response = await ApiDefaultState.characters.deleteCharacter(character.id);
                if (response.status == "Error") {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                }
                else {
                    dispatch({ type: 'CHARACTER_DELETED', id: character.id });
                }
            }
            catch (error) {
                console.error(error);
                dispatch({ type: 'CHARACTER_DELETING', id: character.id, undo: true });
                ToastDispatchables.toast(new ErrorToast(`Server error while deleting ${character.name}.`), dispatch);
            }
        },
        
    rollCharacterDice: (characterId: string, request: RollRequest | null, response: RollResponse): AppThunkAction<CharacterSetPlayerRolling | CharacterReceivePlayerRoll> =>
        async (dispatch) => {
            try {
                dispatch({ type: 'CHARACTER_SET_PLAYER_ROLLING', characterId, request, response });
                await new Promise(resolve => setTimeout(resolve, 3000));
            }
            finally {
                dispatch({ type: 'CHARACTER_RECEIVE_PLAYER_ROLL', characterId, response });
            }
        },

    declareHand: (characterId: string, handType: HandTypeValue): AppThunkAction<ToastAction> => 
        async (dispatch, getState) => {
            try {
                const result = await ApiDefaultState.characters.declareHand(characterId, handType);
                if (result.status == 'Error') {
                    ToastDispatchables.toast(new ErrorToast("Error declaring hand."), dispatch);
                }
            }
            catch (e) {
                console.error(e);
                ToastDispatchables.toast(new ErrorToast("Error declaring hand."), dispatch);
            }
        }
}

const dispatchLibraryActions = (entityInitializers: CharacterEntityInitializers, dispatch: (action: LibraryAction) => void) => {
    if (entityInitializers.competencies.any()) {
        dispatch({ type: 'LIBRARY_COMPETENCIES_LOADED', competencies: entityInitializers.competencies });
    }
    if (entityInitializers.conditions.any()) {
        dispatch({ type: 'LIBRARY_CONDITIONS_LOADED', conditions: entityInitializers.conditions });
    }
    if (entityInitializers.items.any()) {
        dispatch({ type: 'LIBRARY_ITEMS_LOADED', items: entityInitializers.items });
    }
    if (entityInitializers.minions.any()) {
        dispatch({ type: 'LIBRARY_MINIONS_LOADED', minions: entityInitializers.minions });
    }
    if (entityInitializers.perks.any()) {
        dispatch({ type: 'LIBRARY_PERKS_LOADED', perks: entityInitializers.perks });
    }
    if (entityInitializers.personalities.any()) {
        dispatch({ type: 'LIBRARY_PERSONALITIES_LOADED', personalities: entityInitializers.personalities });
    }
    if (entityInitializers.rhetorics.any()) {
        dispatch({ type: 'LIBRARY_RHETORICS_LOADED', rhetorics: entityInitializers.rhetorics });
    }
    if (entityInitializers.skills.any()) {
        dispatch({ type: 'LIBRARY_SKILLS_LOADED', skills: entityInitializers.skills });
    }
}