import React from "react";
import { Die } from "../../../figures/Die"


export const CreateFigureDiceRows = (texts: string[]) => {
    const tableContents: React.ReactNode[] = [];
    for (let i = 0; i < texts.length; i++) {
        tableContents.push(<tr key={i}>
            <td className="align-center dice-column">{i+1}</td>
            <td>{texts[i]}</td>
        </tr>)
    }
    return tableContents;
}