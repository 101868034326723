import React from "react"

export const ComingSoon = () => (
    <main>
        <div className="contents">
            <h1>Coming Soon</h1>
            <p>
                This feature isn't complete yet! Come back later.
            </p>
        </div>
    </main>
)