import React from "react";
import { ArticleNavLink } from "../../articles/navigation/article-navigation/ArticleNavLink";
import { SettlementFeaturesTable, SettlementPoliticalFeaturesTable, SettlementScaleTable } from "../../rules/figures/generation/adventures/CreatingSettlementsFigures";

export const CreatingSettlementsGuideLink = new ArticleNavLink({
    name: "Creating Settlements",
    path: "#CreatingSettlements",
    render: () => <CreatingSettlementsGuide />
})

const CreatingSettlementsGuide = () => (
    <section id={CreatingSettlementsGuideLink.hash}>
        <h2>{CreatingSettlementsGuideLink.name}</h2>
        <p>
            The tables below contain information on creating a settlement or city.
        </p>


        <h3>Scale</h3>
        <p>
            Not all settlements are the same size! The table below provides differently scaled settlements to fit the feel of your adventure.
        </p>
        <SettlementScaleTable />


        <h3>Features</h3>
        <p>
            Below are some interesting features to add to a settlement.
        </p>
        <SettlementFeaturesTable />

        
        <h3>Leadership</h3>
        <p>
            Below indicates how the settlement might organize itself politically.
        </p>
        <SettlementPoliticalFeaturesTable />
    </section>
)