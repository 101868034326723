import React from "react";
import { ArticleProps } from "../../articles/ArticleProps";
import { ArticleNavLink } from "../../articles/navigation/article-navigation/ArticleNavLink";
import { RulesArticle } from "../../rules/RulesArticle";
import { GuideRoot } from "../GuideRoot";
import { GuideLinks } from "..";
import { CreatingEncountersGuideLink } from "./CreatingEncounters";
import { CreatingSettlementsGuideLink } from "./CreatingSettlements";

export const ContentGenerationGuideLink = new ArticleNavLink({
    path: "GeneratingContent",
    name: "Generating Content",
    render: (index?: number) => <ContentGenerationGuide index={index} siblings={GuideLinks} />,
    sublinks: [
        CreatingEncountersGuideLink,
        CreatingSettlementsGuideLink
    ]
})

const ContentGenerationGuide = (props: ArticleProps) => (
    <RulesArticle {...props} root={GuideRoot} link={ContentGenerationGuideLink}>
        <p>
            In this section, you'll find guidelines to help you create adventure content on the fly! Choose options from the tables or let chance take the wheel and roll dice to determine the outcome or use our table tool to randomly select one. Need some more info to improvise and create a cohesive world? You'll also find plenty of information about the world of <em>500 After Cataclysm</em> and how to incorporate those ideas into your adventures.
        </p>
    </RulesArticle>
)