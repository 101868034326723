import React from "react";
import { ArticleNavLink } from "../../articles/navigation/article-navigation/ArticleNavLink";
import { EnemyTypeRollTable, HumanoidEnemyMotivationsRollTable } from "../../rules/figures/generation/encounters/CombatGenerationFigures";

export const CreatingEncountersGuideLink = new ArticleNavLink({
    name: "Creating Encounters and Antagonists",
    path: "#CreatingEncounters",
    render: () => null
})

const CreatingEncountersGuide = () => (
    <section id={CreatingEncountersGuideLink.hash}>
        <h2>{CreatingEncountersGuideLink.name}</h2>
        <p>
            The tables below contain information on creating encounters and antagonists for the party to face.
        </p>


        <h3>Enemy Type</h3>
        <p>The table below contains various types of enemies that the players might encounter on their adventures.</p>
        <EnemyTypeRollTable />


        <h3>Enemy Motivations</h3>
        <p>The table below contains different types of motivations for humanoid enemies (humans & goblins) your party may face.</p>
        <HumanoidEnemyMotivationsRollTable />
    </section>
)
