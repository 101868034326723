import "./adventure-event.scss";
import React from "react";
import { AdventureEvent, EventLibraryEntities } from "../../../entities/adventures/AdventureEvent";
import { CharacterInitializer, CharacterCollections } from "../../../entities/characters/Character";
import { PlayerAccount } from "../../../entities/user/PlayerAccount";
import { Roll } from "../../figures/Rolls";
import { Adventure } from "../../../entities/adventures/Adventure";
import { Die } from "../../figures/Die";

type Props = {
    adventure: Adventure; //TODO: Think of any way to avoid having to pass this in so as not to call any hooks
    event: AdventureEvent;
    players: PlayerAccount[];
    characters: CharacterInitializer[];
    entities: CharacterCollections;
    includeTimestamp?: boolean;
}

export const AdventureEventComponent = (props: Props) => {
    const { adventure, event, players, characters, entities, includeTimestamp } = props;

    const date = new Date(event.occurred);
    const timestamp = `${date.toLocaleTimeString()} ${date.toLocaleDateString("en-US", { hour12: false })}`;
    const player = players.find(p => p.id == event.playerId);
    const character = characters.find(c => c.ownerId == event.playerId);

    const scope = {...entities, adventure, player, character, values: event.values};
    return (
        <div className="adventure-event">
            <header>{adventure.name}</header>
            <div className="event-main-row">
                <div className="event-text">
                    {GenerateEventContents(event, scope)}
                </div>
                {includeTimestamp && <div className="timestamp">{timestamp}</div>}
            </div>
            {event.rolls.any() && <div className="event-rolls">
                <Roll rolls={event.rolls} />
            </div>}
        </div>
    )
}

const GenerateEventContents = (event: AdventureEvent, scope: EventLibraryEntities): React.ReactNode[] => {
    let text = event.text;
    let match = GetNextMatch(text);
    let children: React.ReactNode[] = [];
    while (match != null) {
        const token = match[0];
        const tokenStart = text.indexOf(token);
        const prefix = text.substring(0, tokenStart);
        children.push(prefix);

        const contents = token.substring(1, token.length-1);
        const parsed = ParseToken(contents, event, scope);
        children.push(parsed);
        
        text = text.substring(tokenStart + token.length, text.length);
        match = GetNextMatch(text);
    }
    return children;
}

const GetNextMatch = (text: string) => text.match(/{(.*?)}/g);

const ParseToken = (token: string, event: AdventureEvent, scope: EventLibraryEntities) => {
    let format = "none";
    const formatSplit = token.split("#");
    if (formatSplit.length > 1) {
        format = formatSplit[0];
        token = formatSplit[1];
    }

    let entity: any = Function("scope", `"use strict";return ${token};`)(scope);
    return FormatValue(format, entity)
}

const FormatValue = (format: string, entity: any): React.ReactNode => {
    switch (format) {
        case "dice": return FormatDice(entity);
        default: return <>{entity}</>;
    }
}

const FormatDice = (entity: any): React.ReactNode => {
    if (!Array.isArray(entity) || entity.length == 0 || typeof(entity[0]) != "number") {
        return null;
    }
    return entity.map((die, index) => <Die key={index}>{die}</Die>);
}