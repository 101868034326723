import React, { useEffect } from 'react';
import { ItemsView } from '../../characters/items/ItemsView';
import { Breadcrumbs } from '../navigation/BreadCrumbs';
import { ItemsRoute, LibraryRootRoute } from '../navigation/LibraryRoutes';
import { ItemViewer } from './ItemViewer';
import { Route, Routes } from 'react-router';
import { ApplicationState } from '../../../store/stores/ApplicationState';
import { LibraryActions } from '../../../store/stores/library/LibraryStore.Actions';
import { useAppDispatch } from '../../../store/configureStore';
import { useSelector } from 'react-redux';

export const ItemsLibrary: React.FC = () => {
    const dispatch = useAppDispatch();
    const { allItemsLoaded } = useSelector((state: ApplicationState) => state.library);
    useEffect(() => {
        if (!allItemsLoaded) {
            dispatch(LibraryActions.loadItems());
        }
    }, [dispatch, allItemsLoaded]);
    return (
        <Routes>
            <Route path=":name" element={<ItemViewer />} />
            <Route path="*" element={<LibraryItemsView />} />
        </Routes>
    );
}

const LibraryItemsView = () => (
    <>
        <Breadcrumbs crumbs={[LibraryRootRoute, LibraryRootRoute.GetAsChild(ItemsRoute)]} />
        <h1>Items</h1>
        <ItemsView useRoute />
    </>
)