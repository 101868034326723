import { LocalAdventure } from "../../../entities/adventures/Adventure";
import { AdventureEvent } from "../../../entities/adventures/AdventureEvent";

export type AdventureState = {
    adventures: LocalAdventure[];
    loaded: boolean;
    eventsByAdventureId: { [adventureId: string]: AdventureEvent[] };
    loadingEvents: { [adventureId: string]: { initialLoadPerformed: boolean, loading: boolean, done: boolean } };
    error: boolean;
}

export const AdventureDefaultState: AdventureState = {
    adventures: [],
    loaded: false,
    eventsByAdventureId: {},
    loadingEvents: {},
    error: false,
}