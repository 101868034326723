import { CharacterInitializer } from "../../../../../entities/characters/Character";
import { ItemFilterInitializer } from "../../../../../entities/filters/ItemFilter";
import { RollRequest, RollResponse } from "../../../../../entities/rolls/Roll";
import { AppThunkAction } from "../../../ApplicationState";
import { CommunalDice } from "../../../../../entities/adventures/Adventure";
import { ToastAction, ToastDispatchables } from "../../../toasts/Toasts.Actions";
import { ErrorToast } from "../../../../../entities/toasts/Toasts";

export type SheetSet = { type: 'SHEET_SET', character: CharacterInitializer }
export type SheetSetPreviewItemInstanceId = { type: 'SHEET_SET_PREVIEW_ITEM_INSTANCE_ID', instanceId?: string }
export type SheetSetPreviewSlotId = { type: 'SHEET_SET_PREVIEW_SLOT_ID', slotId?: string, slotItemInstanceId?: string }
export type SheetShowItemAdder = { type: 'SHEET_SHOW_ITEM_ADDER', addToContainerId?: string }
export type SheetHideItemAdder = { type: 'SHEET_HIDE_ITEM_ADDER' }
export type SheetFilterItems = { type: 'SHEET_FILTER_ITEMS', filter: ItemFilterInitializer }
export type SheetSetRolling = { type: 'SHEET_SET_ROLLING', rollRequest: RollRequest | null }
export type SheetSetHand = { type: 'SHEET_SET_HAND', hand: RollResponse }
export type SheetResetHand = { type: 'SHEET_RESET_HAND' };
export type SheetSetCommunalRolls = { type: 'SHEET_SET_COMMUNAL_DICE', communalDice: CommunalDice }
export type SheetSetRollSkill = { type: 'SHEET_SET_ROLL_SKILL', skillId: string }
export type SheetClearRollSkill = { type: 'SHEET_CLEAR_ROLL_SKILL' }

export type SheetToolAction = SheetSet
    | SheetSetPreviewItemInstanceId
    | SheetSetPreviewSlotId
    | SheetShowItemAdder
    | SheetHideItemAdder
    | SheetFilterItems
    | SheetSetRolling
    | SheetSetHand
    | SheetResetHand
    | SheetSetCommunalRolls
    | SheetSetRollSkill
    | SheetClearRollSkill;

export const SheetToolActions = {    
    setActiveCharacterSheet: (character: CharacterInitializer): AppThunkAction<SheetSet | SheetSetCommunalRolls> =>
        (dispatch) => dispatch({ type: 'SHEET_SET', character: character }),

    setPreviewItem: (instanceId: string): AppThunkAction<SheetSetPreviewItemInstanceId> => 
        (dispatch, getState) => {
            const {previewItemInstanceId} = getState().sheet;
            if (previewItemInstanceId != instanceId) {
                dispatch({ type: 'SHEET_SET_PREVIEW_ITEM_INSTANCE_ID', instanceId })
            }
        },
    
    clearPreviewItem: (): AppThunkAction<SheetSetPreviewItemInstanceId> =>
        (dispatch, getState) => {
            const {previewItemInstanceId} = getState().sheet;
            if (previewItemInstanceId != undefined) {
                dispatch({ type: 'SHEET_SET_PREVIEW_ITEM_INSTANCE_ID' });
            }
        },

    setPreviewSlot: (slotId: string, slotItemInstanceId: string): AppThunkAction<SheetSetPreviewSlotId> =>
        (dispatch, getState) => {
            const { previewSlotId } = getState().sheet;
            if (previewSlotId != slotId) {
                dispatch({ type: 'SHEET_SET_PREVIEW_SLOT_ID', slotId, slotItemInstanceId });
            }
        },

    clearPreviewSlot: (): AppThunkAction<SheetSetPreviewSlotId> =>
        (dispatch, getState) => {
            const { previewSlotId } = getState().sheet;
            if (previewSlotId != undefined) {
                dispatch({ type: 'SHEET_SET_PREVIEW_SLOT_ID' });
            }
        },

    showItemAdder: (addToContainerId?: string): AppThunkAction<SheetShowItemAdder> =>
        (dispatch) => {
            dispatch({ type: 'SHEET_SHOW_ITEM_ADDER', addToContainerId });
        },

    hideItemAdder: (): AppThunkAction<SheetHideItemAdder> =>
        (dispatch) => {
            dispatch({ type: 'SHEET_HIDE_ITEM_ADDER' });
        },

    filterItems: (filter: ItemFilterInitializer): AppThunkAction<SheetFilterItems> =>
        (dispatch) => {
            dispatch({ type: 'SHEET_FILTER_ITEMS', filter });
        },
    
    rollHand: (characterId: string, rollRequest: RollRequest): AppThunkAction<SheetSetHand | SheetSetRolling | ToastAction> =>
        async (dispatch, getState) => {
            try {
                const {api, player: user} = getState();
                dispatch({ type: 'SHEET_SET_ROLLING', rollRequest });
                const characterApi = api.characters;
                const response = await characterApi.roll(user.adventureConnectionId, characterId, rollRequest);                
                
                if (response.status == 'Success') {
                    dispatch({ type: 'SHEET_SET_HAND', hand: response.payload });
                }
                else {
                    ToastDispatchables.toastValidationResults(response.validationResults, dispatch);
                }
            }
            catch (e) {
                ToastDispatchables.toast(new ErrorToast("Error rolling dice."), dispatch);
            }
            finally {
                dispatch({ type: 'SHEET_SET_ROLLING', rollRequest: null });
            }
        },

    resetHand: (): AppThunkAction<SheetResetHand> => 
        (dispatch) => dispatch({ type: 'SHEET_RESET_HAND' }),

    setCommunalRolls: (communalDice: CommunalDice): AppThunkAction<SheetSetCommunalRolls> =>
        (dispatch) => dispatch({ type: 'SHEET_SET_COMMUNAL_DICE', communalDice }),

    setRollSkill: (skillId: string): AppThunkAction<SheetSetRollSkill> =>
        (dispatch) => {
            dispatch({ type: 'SHEET_SET_ROLL_SKILL', skillId });
        },
    
    clearRollSkill: (): AppThunkAction<SheetClearRollSkill> =>
        (dispatch) => {
            dispatch({ type: 'SHEET_CLEAR_ROLL_SKILL' });
        },
}