import { useSelector } from "react-redux";
import { CharacterDice, RollRequest } from "../../../entities/rolls/Roll"
import { ApplicationState } from "../../../store/stores/ApplicationState";
import React from "react";
import { Die } from "../../figures/Die";
import { RollTypeIndicator } from "../../figures/RollTypeIndicators";
type Props = {
    dice: CharacterDice,
    rollRequest?: RollRequest;
};

export const AdventurerDice = (props: Props) => {
    const { rollRequest } = props;
    const { skillId, actionDice, extraDice, rollType } = props.dice;
    const skills = useSelector((app: ApplicationState) => app.library.skills);
    const skill = skills.find(s => s.id == skillId);
    
    if (actionDice.length == 0) {
        return null;
    }

    return (
        <div className="adventurer-dice">
            {actionDice.map((face, index) => (
                <Die key={index} rolling={IsRollingDie(rollRequest, index)}>{face}</Die>
            ))}
            &nbsp;
            {
                <>
                    {extraDice.any() && <>&ndash;</>}
                    {extraDice.map((face, index) => (
                        <Die key={index} rolling={rollRequest != null}>{face}</Die>
                    ))}
                </>
            }
            <RollTypeIndicator rollType={rollRequest?.rollType ?? rollType} />  
            &nbsp;
            {skill && <label className="standout">({skill.name})</label>}
        </div>
    )
}

const IsRollingDie = (rollRequest: RollRequest | undefined, index: number) => {
    if (rollRequest == null) {
        return false;
    }
    return rollRequest.skillId == null || rollRequest.rerollIndices.contains(index);
}