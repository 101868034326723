import React from "react"
import { Route, Routes } from "react-router"
import { ScenarioResults } from "./ScenarioResults"
import { ScenarioViewer } from "./ScenarioViewer"

export const ScenariosLibrary = () => {
    return (
        <Routes>
            <Route path=":name" element={<ScenarioViewer />} />
            <Route path="*" element={<ScenarioResults />} />
        </Routes>
    )
}