import React, { useEffect } from "react"
import { LibraryActions } from "../../../store/stores/library/LibraryStore.Actions";
import { Breadcrumbs } from "../navigation/BreadCrumbs";
import { EnemiesRoute, LibraryRootRoute } from "../navigation/LibraryRoutes";
import { useLocation, useParams } from "react-router";
import { decodeNameFromURI } from "../../../utils/StringUtilities";
import { SortedSet } from "../../../entities/data-structures/SortedSet";
import { StatBlock } from "../../../entities/library/enemies/StatBlock";
import { Loader } from "../../theming/loader/Loader";
import { useAppDispatch, useAppSelector } from "../../../store/configureStore";

export const EnemyViewer = () => {
    const location = useLocation();
    const params = useParams();
    const dispatch = useAppDispatch();
    const { enemies, allEnemiesLoaded } = useAppSelector(app => app.library);

    useEffect(() => {
        if (!allEnemiesLoaded) {
            dispatch(LibraryActions.loadEnemies());
        }
    }, [dispatch, allEnemiesLoaded]);

    if (!allEnemiesLoaded) {
        return <Loader />;
    }
    
    let name = params["name"] as string | undefined;
    if (name == undefined) {
        throw new Error("No enemy name provided.");
    }
    name = decodeNameFromURI(name);

    const set = new SortedSet(enemies);
    const enemy = set.getByName(name);
    if (enemy == undefined) {
        throw new Error("No hazard found.");
    }

    return (
        <>
            <Breadcrumbs crumbs={[LibraryRootRoute, EnemiesRoute, {path: location.pathname, name}]} />
            <StatBlock enemy={enemy} />
        </>
    )
}