import React, { useState } from "react"
import { LocalAdventure } from "../../../entities/adventures/Adventure"
import { LocalCharacterInitializer } from "../../../entities/characters/Character"
import { PlayerAccount } from "../../../entities/user/PlayerAccount"
import { ThemedButton } from "../../inputs/buttons/ThemedButton"
import { AddCharacterMenu } from "./AddCharacterMenu"
import { AdventureActions } from "../../../store/stores/adventures/AdventureStore.Actions"
import { AdventurerDice } from "./AdventurerDice"
import { useAppDispatch, useAppSelector } from "../../../store/configureStore"

type Props = { adventure: LocalAdventure }

export const Adventurers: React.FC<Props> = (props: Props) => {
    const { adventure } = props;
    const { me, players: users } = useAppSelector(app => app.player);
    const { characters, otherPlayerCharacters} = useAppSelector(app => app.character);
    
    if (me == null) {
        return null;
    }
    const allUsers = [...users, me];
    const owner = allUsers.find(u => u.id == adventure.ownerId);
    if (owner == null) {
        return null;
    }
    const players = allUsers.filter(u => adventure.playerIds.contains(u.id));
    const adventureCharacters = [...characters, ...otherPlayerCharacters].filter(c => adventure.characterIds.contains(c.id));
    return (
        <div className="adventurers">
            <div>
                <label className="standout">Game Master:</label> {owner.displayName}
            </div>
            <div>
                <label className="standout">Players:</label>
                {!players.any() && 'No players yet!'}
                {players.map(p => <AdventurePlayer key={p.id} me={me} player={p} characters={adventureCharacters} adventure={adventure} />)}
            </div>
        </div>
    )
}

type PlayerProps = {
    me: PlayerAccount;
    player: PlayerAccount;
    characters: LocalCharacterInitializer[];
    adventure: LocalAdventure;
}
const AdventurePlayer: React.FC<PlayerProps> = (props: PlayerProps) => {
    const { me, player, characters, adventure } = props;
    const dispatch = useAppDispatch();
    const [addingCharacter, setAddingCharacter] = useState(false);
    const character = characters.find(c => c.ownerId == player.id);
    const isPlayer = me.id == player.id;
    let displayName: string;
    if (isPlayer) {
        displayName = "You";
    }
    else {
        displayName = player.displayName != "" ? player.displayName : 'Anonymous';
    }

    const viewAddCharacterMenu = () => {
        setAddingCharacter(true);
    }
    const confirmRemoveCharacter = () => {
        if (character != null && confirm(`Are you sure you want to remove ${character.name} from this adventure?`)) {
            dispatch(AdventureActions.removeCharacterFromAdventure(adventure, character));
        }
    }
    if (character == null) {
        return (
            <div className="adventurer">
                <label>{displayName}</label>
                {isPlayer && <ThemedButton onClick={viewAddCharacterMenu}>Add Character to Adventure</ThemedButton>}
                {addingCharacter && <AddCharacterMenu adventure={adventure} onClose={() => setAddingCharacter(false)} />}
                <br />
                {displayName} {isPlayer ? "don't" : "doesn't"} have a character for this adventure yet.
            </div>
        );
    }
    return (
        <div className="adventurer">
            <label>{displayName}</label>
            <div>
                <label>{character.name}</label>
                <div>lv. {character.level} {character.kinship} {character.className}</div>
                <AdventurerDice dice={character.currentDice} rollRequest={character.rollRequest} />
                {isPlayer && <ThemedButton onClick={confirmRemoveCharacter}>Remove From Adventure</ThemedButton>}
            </div>
        </div>
    );
}