import React from "react";
import { LocalAdventure } from "../../../entities/adventures/Adventure"
import { BreakdownWindow } from "../../theming/breakdown-window";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store/stores/ApplicationState";
import { ThemedButton } from "../../inputs/buttons/ThemedButton";
import { AdventureActions } from "../../../store/stores/adventures/AdventureStore.Actions";
import { CharacterInitializer } from "../../../entities/characters/Character";
import { useAppDispatch } from "../../../store/configureStore";

type Props = {
    adventure: LocalAdventure;
    onClose: () => unknown;
}

export const AddCharacterMenu = (props: Props) => {
    const { adventure, onClose } = props;
    const dispatch = useAppDispatch();
    const {adventures} = useSelector((app: ApplicationState) => app.adventure);
    const {characters} = useSelector((app: ApplicationState) => app.character);
    const addCharacterToAdventure = (character: CharacterInitializer) => {
        dispatch(AdventureActions.addCharacterToAdventure(adventure, character));
        onClose();
    }
    const charactersAssignedToAdventures = adventures.mapMany(adventure => adventure.characterIds);
    const unassignedCharacters = characters.filter(character => !charactersAssignedToAdventures.contains(character.id));
    return (
        <BreakdownWindow close={onClose} heading={<h1>Your Characters</h1>}>
            {unassignedCharacters.map(character => (
                <div key={character.id}>
                    {character.name} <ThemedButton onClick={() => addCharacterToAdventure(character)}>Add To Adventure</ThemedButton>
                </div>
            ))}
        </BreakdownWindow>
    );
}