import React from "react";
import { Die } from "./Die";
import { AdvantageIcon, DisadvantageIcon } from "../icons/IndicatorIcons";
import { RollType } from "../../entities/rolls/Roll";

export const RollTypeIndicator = ({rollType}: {rollType?: RollType}) => {
    if (rollType == RollType.Advantage) {
        return <AdvantageIndicator />;
    }
    if (rollType == RollType.Disadvantage) {
        return <DisadvantageIndicator />;
    }
    return null;
}
export const AdvantageIndicator = () => <Die><AdvantageIcon /></Die>;
export const DisadvantageIndicator = () => <Die><DisadvantageIcon /></Die>