import { Middleware } from "redux";
import { CharacterInitializer } from "../../entities/characters/Character";
import { AdventureActions } from "../../store/stores/adventures/AdventureStore.Actions";
import { CharacterActions } from "../../store/stores/collection/characters/CharacterStore.Actions";
import { UserActions } from "../../store/stores/players/PlayerStore.Actions";
import { AppStore, BaseHub } from "../Hub.Base";
import { AccountApiConfig } from "./config/AccountApiConfig";
import { AdventureHubConfig } from "./config/AdventureHubConfig";
import { ApplicationState } from "../../store/stores/ApplicationState";
import { AdventureHubAction } from "../../store/stores/api/ApiStore.Actions";

export class AdventureHub extends BaseHub<typeof AdventureHubConfig> {
    protected get audience(): string {
        return AccountApiConfig.audience;
    }
    
    protected async start(): Promise<void> {
        await super.start();
        const connection = await this.connection;
        
        this.invokeDispatch(UserActions.setAdventureConnection)(connection.connectionId);
        connection.onclose(() => this.invokeDispatch(UserActions.setAdventureConnection)(null));

        connection.on('PlayerJoined', this.invokeDispatch(AdventureActions.playerJoined));
        connection.on('PlayerLeft', this.invokeDispatch(AdventureActions.playerLeft));
        connection.on('PushNewEvent', this.invokeDispatch(AdventureActions.pushNewEvent));
        connection.on('CharacterAdded', (id: string, character: CharacterInitializer) => {
            this.invokeDispatch(CharacterActions.receiveUnownedCharacter)(character);
            this.invokeDispatch(AdventureActions.receiveCharacterAdded)(id, character.id);
        });
        connection.on('CharacterRemoved', this.invokeDispatch(AdventureActions.receivedCharacterRemoved));
        connection.on('SetCommunalDice', this.invokeDispatch(AdventureActions.receiveCommunalDice));
        connection.on('PlayerRolledDice', this.invokeDispatch(CharacterActions.rollCharacterDice));
    }

    private static instance: AdventureHub | null = null;

    public static CreateInstance(store: AppStore, config: typeof AdventureHubConfig) {
        return this.instance = new AdventureHub(store, config);
    }

    public static Instance(): AdventureHub {
        if (this.instance == null) {
            throw new Error("Attempted to retrieve hub instance before instantiation.");
        }
        return this.instance;
    }

    public static CreateMiddleware(): Middleware[] {
        return [
            ({ getState }) => (next) => (action: unknown) => {
                try {
                    AdventureHub.Reduce(getState, action)
                }
                catch (e) {
                    console.error(e);
                }
                finally {
                    next(action ?? { type: "" });
                }
            }
        ]
    }

    public static Reduce(getState: () => ApplicationState, incomingAction: unknown) {
        const action = incomingAction as AdventureHubAction;
        switch (action.type) {
            default: BaseHub.Reduce(getState, action);
        }
    }
}